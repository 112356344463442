(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/customer-login/assets/javascripts/after-logout-listener.js') >= 0) return;  svs.modules.push('/components/components/customer-login/assets/javascripts/after-logout-listener.js');

(function(svs) {
  'use strict';

  svs.core.userSession.on('afterLogout', function(e) {
    if (e.role === svs.core.userSession.roles.PLAYER) {
      if (e.reason === svs.core.userSession.logoutReason.INVALID_SESSION) {
        svs.components.customer_login.login(); 

        e.preventDefault();
      } else if (svs.core.data.roles.indexOf(e.role) > -1) {
        window.location.href = svs.core.urlMapping.get('startPage') || '/'; 

        e.preventDefault();
      }
    }
  });
})(svs);


 })(window);