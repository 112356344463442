(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/customer-login/assets/javascripts/login.js') >= 0) return;  svs.modules.push('/components/components/customer-login/assets/javascripts/login.js');
"use strict";

(function ($, svs) {
  'use strict';

  svs = svs || {};
  svs.components = svs.components || {};
  svs.components.customer_login = svs.components.customer_login || {};
  svs.components.customer_login.login = function () {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let extraParams = '';
    if (options.extraParams) {
      extraParams = Object.keys(options.extraParams).reduce((acc, key) => {
        if (typeof options.extraParams[key] !== 'undefined') {
          return "".concat(acc, "&").concat(encodeURIComponent(key), "=").concat(encodeURIComponent(options.extraParams[key]));
        }
        return acc;
      }, '');
    } else if (svs.core.data.tenant === 'tb') {
      extraParams = '&extra=no';
    }
    const prevUrl = svs.utils.url.makeInternal(svs.utils.url.currentPathEncodedParams());
    const returnUrl = encodeURIComponent(options.returnUrl || prevUrl);
    let cancelUrl = ''; 

    if (svs.core.data.tenant === 'tb') {
      if ((prevUrl === null || prevUrl === void 0 ? void 0 : prevUrl.indexOf('?')) > -1) {

        const prevUrlSplit = prevUrl.split('?');
        const base = prevUrlSplit[0];
        const query = prevUrlSplit.slice(1).join('?');
        const URLSearch = new URLSearchParams(query);

        URLSearch.delete('pay');
        URLSearch.delete('betala');
        URLSearch.delete('containerId');
        if (URLSearch.size > 0) {
          cancelUrl = "&cancelUrl=".concat(encodeURIComponent("".concat(base, "?").concat(URLSearch.toString())));
        } else {
          cancelUrl = "&cancelUrl=".concat(encodeURIComponent(base));
        }
      } else {
        cancelUrl = "&cancelUrl=".concat(encodeURIComponent(prevUrl));
      }
    }
    svs.utils.url.navigate(svs.utils.url.join(options.newCustomer ? svs.core.urlMapping.get('customerRegistrationHome') : svs.core.urlMapping.get('loginHome'), "?returnUrl=".concat(returnUrl).concat(cancelUrl).concat(extraParams, "&w=t").concat(options.returnNewCustomer ? '&rnc=true' : '')));
  };
})(jQuery, svs);

 })(window);